import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import KbaQR from "./KbaQR";
import VerificationData from "./VerificationData";
import { TbConfetti as CIcon } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import SignerInfo from "./SignerInfo";
import { ISignerData } from "../../../store/zustandType";
import { useAuth } from "../../../utils/use-auth";
import { getSessionDetails } from "../../../utils/utils-apis";
import DemoKbaQR from "./DemoKba/DemoKbaQR";
import DemoVerificationData from "./DemoKba/DemoVerificationData";

const KbaVerification = ({
  isOpen,
  onClose,
  onOpen,
  from,
  sessionId,
}: {
  from?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  sessionId?: number;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const userId = localStorage.getItem("UserID");
  const [result, setResult] = useState<string>("");
  const [isBiometric, setIsBiometric] = useState<boolean>(false);
  const [signerData, setSignerData] = useState<ISignerData>({
    dob: "",
    lastSsn: "",
  });
  const [isDemoSession, setIsDemoSession] = useState<boolean>(false);

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(configuration, sessionId).then((res) => {
        setIsDemoSession(res?.isDemoSession as boolean);
        if (res.user.role === "notary") {
          if (
            res.kbaLogs?.find((el) => Number(el?.userId) === Number(userId))
              ?.kbaLogId !== "knownByNotary" &&
            (res.notary?.state === "NJ" || res.notary?.state === "NY")
          ) {
            setIsBiometric(true);
          } else {
            setIsBiometric(false);
          }
        } else {
          if (
            res?.specificNotaryState === "NJ" ||
            res.specificNotaryState === "NY"
          ) {
            setIsBiometric(true);
          } else {
            setIsBiometric(false);
          }
        }
      });
    }
  }, [sessionId]);

  const handleClose = () => {
    setResult("");
    onClose();
    navigate("/");
    // window.location.href = "/";
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={"3xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent px={4} py={3}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            KBA Verification
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody mb={4} mt={2}>
          <>
            {isDemoSession ? (
              <DemoVerificationData
                from={from}
                signerData={signerData}
                onSetResult={(result) => setResult(result)}
              />
            ) : signerData.dob === '' ? (<SignerInfo
              sessionId={sessionId}
              signerData={signerData}
              onSetSignerData={(value) => setSignerData(value)}
            />) : (
              <VerificationData
                from={from}
                onKbaModalClose={onClose}
                onSetResult={(result) => setResult(result)}
                signerData={signerData}
                isBiometric={isBiometric}
                sessionId={sessionId}
              />
            )}
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default KbaVerification;
